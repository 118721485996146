"use client";
import { useState } from "react";
import Image from "next/image";
import Link from "next/link";
import { useAuth } from "@clerk/nextjs";
import { UserButton } from "@clerk/nextjs";
import { Button } from "@/components/ui/button";
import { Menu, X } from "lucide-react";

export default function MobileNavigationBar() {
  const { isSignedIn } = useAuth();
  const [isOpen, setIsOpen] = useState(false);
  const isDevComplete = true; // Changed to false for demonstration

  const toggleMenu = () => setIsOpen(!isOpen);

  return (
    <nav className="fixed top-0 left-0 right-0 z-50">
      <div className="flex justify-between items-center p-4">
        <Link href="/">
          <button className="focus:outline-none">
            <Image src="/logo.png" alt="Melo Logo" width={100} height={40} priority />
          </button>
        </Link>
        <button onClick={toggleMenu} className="text-white">
          {isOpen ? <X size={24} /> : <Menu size={24} />}
        </button>
      </div>
      {isOpen && (
        <div className="fixed inset-0 backdrop-blur-lg bg-black/30">
          <div className="flex flex-col h-full p-6 space-y-6 text-white">
            <div className="flex justify-between items-center">
              <Link href="/" passHref>
                <button className="focus:outline-none">
                  <Image src="/logo.png" alt="Melo Logo" width={100} height={40} priority />
                </button>
              </Link>
              <button onClick={toggleMenu}>
                <X size={24} />
              </button>
            </div>
            <div className="space-y-4">
              <h3 className="text-xl font-semibold">Product</h3>
              {isDevComplete ? (
                <>
                  <Link href="/pages/ios-waitlist" className="block hover:text-gray-300">iOS Waitlist</Link>
                  <Link href="/pages/drop-podcast" className="block hover:text-gray-300">Drop Podcast</Link>
                  <Link href="/pages/back-melo" className="block hover:text-gray-300">Back Melo</Link>
                </>
              ) : (
                <Link href="/pages/coming-soon" className="block hover:text-gray-300">Coming Soon</Link>
              )}
            </div>
            {isDevComplete ? (
              <Link href="/pages/pricing" className="text-xl font-semibold hover:text-gray-300">Pricing</Link>
            ) : null}
            <div className="space-y-4">
              <h3 className="text-xl font-semibold">Company</h3>
              <Link href="/pages/about" className="block hover:text-gray-300">About</Link>
              <Link href="/pages/privacy-policy" className="block hover:text-gray-300">Privacy Policy</Link>
              <Link href="/pages/terms-of-service" className="block hover:text-gray-300">Terms of Service</Link>
            </div>
            <Link href="/pages/contact" className="text-xl font-semibold hover:text-gray-300">Contact</Link>
            {isSignedIn ? (
              <div className="space-y-4">
                <Link href="/conversation">
                  <Button className="w-full bg-transparent text-white border border-white hover:bg-white hover:text-black transition-colors duration-300 rounded-full">
                    dashboard
                  </Button>
                </Link>
                <UserButton afterSignOutUrl="/" />
              </div>
            ) : isDevComplete ? (
              <div className="space-y-4">
                <Link href="/sign-in">
                  <Button className="w-full bg-transparent text-white border border-white hover:bg-white hover:text-black transition-colors duration-300 rounded-full">
                    Sign In
                  </Button>
                </Link>
                <div className="h-2"></div>
                <Link href="/sign-up">
                  <Button className="w-full bg-transparent text-white border border-white hover:bg-white hover:text-black transition-colors duration-300 rounded-full">
                    Sign Up
                  </Button>
                </Link>
              </div>
            ) : (
              <div className="space-y-4">
                <Button 
                  disabled 
                  className="w-full bg-[#333333] text-white border border-white opacity-90 cursor-not-allowed rounded-full hover:bg-[#444444] transition-colors duration-300"
                >
                  Coming Soon :)
                </Button>
              </div>
            )}
          </div>
        </div>
      )}
    </nav>
  );
}