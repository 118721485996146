"use client";
import { useState, useEffect } from "react";
import Image from "next/image";
import Link from "next/link";
import { useAuth } from "@clerk/nextjs";
import { UserButton } from "@clerk/nextjs";
import { Button } from "@/components/ui/button";
import MobileNavigationBar from "./MobileNavigationBar";

interface NavigationBarProps {
  isDevComplete: boolean;
}

const NavigationBar: React.FC<NavigationBarProps> = ({ isDevComplete }) => {
  const { isSignedIn } = useAuth();
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1024);
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  if (isMobile) {
    return <MobileNavigationBar />;
  }

  return (
    <nav className="flex justify-between items-center p-6 bg-transparent z-50">
      <Link href="/">
        <Image src="/logo.png" alt="Melo Logo" width={120} height={40} priority />
      </Link>
      <div className="flex items-center space-x-9 border border-white rounded-full px-6 py-3 text-lg">
        <div className="relative group">
          <span className="text-white cursor-pointer">product</span>
          <div className="absolute left-1/2 transform -translate-x-1/2 invisible group-hover:visible opacity-0 group-hover:opacity-100 transition-all duration-300 bg-white rounded-lg shadow-lg mt-3 p-3 space-y-3 w-60 z-50">
            {isDevComplete && !isSignedIn && (
              <Link href="/sign-up" className="block px-6 py-3 text-gray-800 hover:bg-gray-100 rounded-md whitespace-nowrap">try the demo</Link>
            )}
            <Link href="/pages/ios-waitlist" className="block px-6 py-3 text-gray-800 hover:bg-gray-100 rounded-md whitespace-nowrap">ios waitlist</Link>
            <Link href="/pages/drop-podcast" className="block px-6 py-3 text-gray-800 hover:bg-gray-100 rounded-md whitespace-nowrap">drop podcast</Link>
          </div>
        </div>
        <Link href="/pages/pricing" className="text-white hover:text-gray-300">pricing</Link>
        <div className="relative group">
          <span className="text-white cursor-pointer">company</span>
          <div className="absolute left-1/2 transform -translate-x-1/2 invisible group-hover:visible opacity-0 group-hover:opacity-100 transition-all duration-300 bg-white rounded-lg shadow-lg mt-3 p-3 space-y-3 w-60 z-50">
            <Link href="/pages/about" className="block px-6 py-3 text-gray-800 hover:bg-gray-100 rounded-md whitespace-nowrap">about</Link>
            <Link href="/pages/privacy-policy" className="block px-6 py-3 text-gray-800 hover:bg-gray-100 rounded-md whitespace-nowrap">privacy policy</Link>
            <Link href="/pages/terms-of-service" className="block px-6 py-3 text-gray-800 hover:bg-gray-100 rounded-md whitespace-nowrap">terms of service</Link>
            <Link href="/pages/back-melo" className="block px-6 py-3 text-gray-800 hover:bg-gray-100 rounded-md whitespace-nowrap">back melo</Link>
          </div>
        </div>
        <Link href="/pages/contact" className="text-white hover:text-gray-300">contact</Link>
      </div>
      <div className="flex items-center space-x-6">
        {isSignedIn ? (
          <>
            <Link href="/conversation">
              <Button variant="outline" className="rounded-full text-white bg-transparent border-white hover:bg-white hover:text-gray-800 px-6 py-2 text-base">
                dashbaord
              </Button>
            </Link>
            <UserButton afterSignOutUrl="/" />
          </>
        ) : (
          <>
            {isDevComplete ? (
              <>
                <Link href="/sign-up">
                  <Button variant="outline" className="rounded-full text-white bg-transparent border-white hover:bg-white hover:text-gray-800 px-6 py-2 text-base">
                    sign up
                  </Button>
                </Link>
                <Link href="/sign-in">
                  <Button variant="outline" className="rounded-full text-white bg-transparent border-white hover:bg-white hover:text-gray-800 px-6 py-2 text-base">
                    sign in
                  </Button>
                </Link>
              </>
            ) : (
              <Button 
                disabled 
                className="bg-[#333333] text-white border border-white opacity-90 cursor-not-allowed rounded-full hover:bg-[#444444] transition-colors duration-300 px-6 py-2 text-base"
              >
                Coming Soon :)
              </Button>
            )}
          </>
        )}
      </div>
    </nav>
  );
};

export default NavigationBar;